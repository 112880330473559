<template>
  <div class="job-profession-item" @click="openRegulationLink">
    <div class="job-profession-item__image">
      <span>
        <svg
          width="81"
          height="81"
          viewBox="0 0 81 81"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M67.5 75.9375H13.5V5.0625H50.625L67.5 21.9375V75.9375Z" fill="#F1F1FF" />
          <path d="M64.9687 23.625H48.9375V7.59375L64.9687 23.625Z" fill="#DDDDFF" />
          <path d="M27 35.4375H55.6875V38.8125H27V35.4375Z" fill="#DDDDFF" />
          <path d="M27 42.1875H48.9375V45.5625H27V42.1875Z" fill="#DDDDFF" />
          <path d="M27 48.9375H55.6875V52.3125H27V48.9375Z" fill="#DDDDFF" />
          <path d="M27 55.6875H48.9375V59.0625H27V55.6875Z" fill="#DDDDFF" />
        </svg>
      </span>
    </div>
    <div class="job-profession-item__content">
      <div class="job-profession-item__content-header">
        <div class="job-profession-item__content-date">
          {{ convertDate(roleRegulation.datetime_of_creation) }}
        </div>
        <div class="job-profession-item__content-views">
          <i class="icon-eye"></i>
          <span class="count">{{ roleRegulation.views.length }}</span>
        </div>
      </div>
      <h4 class="job-profession-item__content-title">{{ roleRegulation.name }}</h4>
      <p class="job-profession-item__content-text">
        {{ roleRegulation.preview }}
      </p>
    </div>
    <div class="job-profession-item__footer">
      <div class="job-profession-item__likes" v-if="isTestPassed">
        <span class="icon" @click.stop="sendLike">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            :fill="this.currentUserLike ? '#8D96EC' : 'transparent'"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.34255 6.77795C2.5687 6.23198 2.90017 5.7359 3.31804 5.31804C3.7359 4.90017 4.23198 4.5687 4.77795 4.34255C5.32392 4.1164 5.90909 4 6.50004 4C7.09099 4 7.67616 4.1164 8.22213 4.34255C8.7681 4.5687 9.26417 4.90017 9.68204 5.31804L11 6.63604L12.318 5.31804C13.162 4.47412 14.3066 4.00001 15.5 4.00001C16.6935 4.00001 17.8381 4.47412 18.682 5.31804C19.526 6.16196 20.0001 7.30656 20.0001 8.50004C20.0001 9.69352 19.526 10.8381 18.682 11.682L11 19.364L3.31804 11.682C2.90017 11.2642 2.5687 10.7681 2.34255 10.2221C2.1164 9.67616 2 9.09099 2 8.50004C2 7.90909 2.1164 7.32392 2.34255 6.77795Z"
              stroke="#8D96EC"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <span class="count">{{ roleRegulation.likes.length }}</span>
      </div>
      <div
        class="job-profession-item__button job-profession-item__button_test"
        v-if="!isTestPassed && isTest && !isHiddenTest"
        @click.stop="openTest"
      >
        <span class="text">Тест</span>
        <span class="indicator" v-if="isTestRequired"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import { ru } from "date-fns/locale";

import { domain } from "@/globalVariables";
import httpClient from "@/api/client/httpClient";

export default {
  name: "JobProfessionItem",
  mixins: [],
  props: {
    roleRegulation: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    openTest() {
      this.$emit("openTestByButton", {
        test_id: this.roleRegulation.test_with_user_results
          ? this.roleRegulation.test_with_user_results.id
          : null,
        regulation_id: this.roleRegulation.id
      });
    },
    // Форматирование даты
    convertDate(date) {
      const data = format(new Date(date), "dd MMM yyyy HH:mm", {
        locale: ru
      });
      return data;
    },

    // Открыть памятку в новом окне
    openRegulationLink() {
      const routeData = this.$router.resolve({
        name: "RegulationPage",
        params: { regulation_id: this.roleRegulation.id }
      });
      window.open(routeData.href, "_blank");
    },

    // Поставить / удалить лайк
    async sendLike() {
      httpClient({
        url: `/Like/${
          this.currentUserLike
            ? `actionDelete/${this.roleRegulation.current_user_like.id}`
            : "actionCreate"
        }`,
        method: this.currentUserLike ? "DELETE" : "POST",
        data: [
          {
            likeable_id: !this.currentUserLike ? this.roleRegulation.id : undefined,
            likeable_type: !this.currentUserLike ? "regulations" : undefined
          }
        ]
      })
        .then(() => {
          this.$root.$emit("goGetInfoUser");
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters.getUserId;
    },

    // Проверка, что текущий пользователь поставил лайк
    currentUserLike() {
      if (this.roleRegulation.current_user_like) {
        return true;
      } else {
        return false;
      }
    },

    // Проверка на обязательность прохождения теста
    isTestRequired() {
      let required = false;
      if (this.roleRegulation.test_with_user_results) {
        if (this.roleRegulation.test_with_user_results.user_status.length) {
          this.roleRegulation.test_with_user_results.user_status.forEach((item) => {
            if (item.is_required) {
              required = true;
            }
          });
        }
      }
      return required;
    },

    // Проверка на то, что тест пройден на 100%
    isTestPassed() {
      let isPassed = true;
      if (this.roleRegulation.test_with_user_results) {
        if (this.roleRegulation.test_with_user_results.user_status.length) {
          this.roleRegulation.test_with_user_results.user_status.forEach((item) => {
            if (!item.is_passed) {
              isPassed = false;
            }
          });
        } else if (!this.roleRegulation.test_with_user_results.user_status.length) {
          isPassed = false;
        }
      }
      return isPassed;
    },

    // Проверка на то, что тест существует
    isTest() {
      if (this.roleRegulation.test_with_user_results) {
        return true;
      } else {
        return false;
      }
    },

    // Скрыт ли тест
    isHiddenTest() {
      if (this.roleRegulation.test_with_user_results) {
        if (this.roleRegulation.test_with_user_results.is_hidden) {
          return true;
        }
      } else {
        return false;
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";

.job-profession-item {
  display: flex;
  flex-direction: column;
  width: 235px;
  height: 340px;
  margin-right: 20px;
  margin-top: 20px;
  padding-bottom: 10px;

  border: 1px solid $color-contour;
  border-radius: 5px;
  background-color: $color-white;
  cursor: pointer;
  transition: all 0.2s ease;

  &:nth-child(6n) {
    margin-right: 0;
  }

  &:hover {
    box-shadow: 2px 5px 10px 0 rgba(0, 0, 0, 0.05);
  }

  &:active {
    box-shadow: 2px 5px 10px 0 rgba(0, 0, 0, 0.05);
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 139px;

    border-bottom: 1px solid $color-contour;

    span {
      svg {
        flex-shrink: 0;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 10px;

    &-header {
      margin-bottom: 5px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      color: $color-text-secondary;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
    }

    &-views {
      display: flex;
      align-items: center;

      .icon-eye {
        margin-right: 5px;
        margin-bottom: 2px;
        font-size: 20px;
      }
    }

    &-title {
      margin: 0 0 5px 0;
      padding: 0;
      overflow: hidden;

      font-weight: 400;
      line-height: 20px;
      font-size: 14px;
      color: $color-text-accent;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &-text {
      margin: 0;
      padding: 0;
      overflow: hidden;

      color: $color-text-primary-1;
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  &__footer {
    margin-top: auto;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  &__likes {
    display: flex;
    align-items: center;

    user-select: none;

    .icon {
      margin-right: 5px;

      cursor: pointer;

      &:hover {
        svg {
          fill: $color-button-hover;
          transform: scale(1.1);
        }
      }

      &:active {
        svg {
          fill: $color-button-click;
          transform: scale(0.9);
        }
      }

      svg {
        margin-bottom: 3px;

        transition: all 0.2s ease;
      }
    }
  }

  .count {
    color: $color-text-primary-2;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &__button {
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 126px;
    height: 32px;

    transition: all 0.1s ease;

    &_test {
      background-color: $color-button-light-1;

      &:hover {
        background-color: $color-button-light-2;
      }

      &:active {
        background-color: $color-button-light-2;
      }

      .text {
        color: $color-interactive;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      .indicator {
        position: absolute;
        top: -1px;
        right: -1px;

        width: 5px;
        height: 5px;

        border-radius: 50%;
        background-color: $color-charts-red-1;
      }
    }
  }
}
</style>
